import {Injectable, Injector} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {MediaLibraryEnvService} from "@sv/sv-common/lib/env/media-library-env.service";
import {Observable} from "rxjs";
import {FileMetadataResponseDto, FileShowDto, FilesService, FolderShowDto} from "@sv/swagger/media-manager-api";

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  constructor(
    private httpClient: HttpClient,
    private mediaLibraryEnvService: MediaLibraryEnvService,
    private injector: Injector,
    private readonly filesService: FilesService
  ) {
  }

  encodePath(path: string) {
    return path.replace(/\?/g, '%3F').replace(/&/g, '%26').replace(/#/g, '%23');
  }


  public loadFiles(path: string) {
    return this.httpClient.get<FileShowDto[]>(`${this.mediaLibraryEnvService.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/overview/${this.mediaLibraryEnvService.mediaLibraryEnvConfig.bucket}/${this.encodePath(path)}`);
  }

  public loadFileByPath(fullPathWithBucket: string) {
    return this.httpClient.get<FileShowDto>(`${this.mediaLibraryEnvService.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/dtos/${this.encodePath(fullPathWithBucket)}`);
  }

  public loadMetadata(path: string): Observable<FileMetadataResponseDto> {
    return this.httpClient.get(`${this.mediaLibraryEnvService.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/metadata/${this.encodePath(path)}`, {
      observe: "body",
    })
  }


  uploadFile(bucketName: string, path: string, file: File | Blob, name: string, fileName: string, altText: string | null, keywords: string[] | null, source: string | null, isChildFaceVisible: boolean | null) {
    const formData = new FormData();
    if (this.mediaLibraryEnvService.mediaLibraryEnvConfig.multipartFileName) {
      formData.append(this.mediaLibraryEnvService.mediaLibraryEnvConfig.multipartFileName, file, name);
      formData.append("bucket", bucketName);
      formData.append("path", path);
      formData.append("fileName", fileName);
      if (altText) {
        formData.append("altText", altText);
      }
      if (keywords) {
        keywords.forEach(k => {
          formData.append("keywords", k);
        })
      }
      if (source) {
        formData.append("source", source);
      }
      if (isChildFaceVisible) {
        formData.append("isChildFaceVisible", (isChildFaceVisible + "") || "false");
      }
      return this.httpClient.post<FileShowDto>(`${this.mediaLibraryEnvService.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/`, formData, {
        observe: "events",
        reportProgress: true
      })
    } else {
      console.error('Multipart file name has not been set. Please set it MediaLibraryModalEnvModule. E.g. MediaLibraryModalEnvModule.forRoot({...})');
      throw new Error('Multipart file name has not been set. Please set it MediaLibraryModalEnvModule. E.g. MediaLibraryModalEnvModule.forRoot({...})');
    }
  }

  replaceFile(bucketName: string, path: string, file: File | Blob, name?: string) {
    if (this.mediaLibraryEnvService.mediaLibraryEnvConfig.functions?.replace?.renameFunctionName) {
      return this.injector.get(this.mediaLibraryEnvService.mediaLibraryEnvConfig.functions?.replace?.replaceService)[this.mediaLibraryEnvService.mediaLibraryEnvConfig.functions?.replace?.renameFunctionName](bucketName, this.encodePath(path.split('/').slice(0, -1).join('/') + '/' + name), bucketName, this.encodePath(path), file);
    } else {
      console.error('No replace service or replace function set.', this.mediaLibraryEnvService.mediaLibraryEnvConfig);
    }
  }

  downloadFile(path: string) {
    return this.httpClient.get(`${this.mediaLibraryEnvService.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/${this.encodePath(path)}`, {
      responseType: 'blob',
      observe: "response"
    });
  }

  deleteFile(path: string) {
    return this.httpClient.delete(`${this.mediaLibraryEnvService.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/${this.encodePath(path)}`);
  }

  renameFile(bucketName: string, path: string, oldName: string, newName: string) {
    return this.filesService.renameFile({
      newBucket: bucketName,
      oldBucket: bucketName,
      newPath: path,
      oldPath: path,
      oldName: oldName,
      newName: newName
    });
    // if (this.mediaLibraryEnvService.mediaLibraryEnvConfig.functions?.rename?.renameFunctionName) {
    // return this.injector.get(this.mediaLibraryEnvService.mediaLibraryEnvConfig.functions?.rename?.replaceService)[this.mediaLibraryEnvService.mediaLibraryEnvConfig.functions?.rename?.renameFunctionName](bucketName, path.split('/').slice(0, -1).join('/') + '/' + newName, bucketName, path, file);
    // } else {
    //   console.error('No replace service or replace function set.', this.mediaLibraryEnvService.mediaLibraryEnvConfig);
    // }
  }

  getFoldersByPath(path: string) {
    return this.httpClient.get<FolderShowDto[]>(`${this.mediaLibraryEnvService.mediaLibraryEnvConfig.staticResourceManagerBasePath}/folders/${this.mediaLibraryEnvService.mediaLibraryEnvConfig.bucket}/${this.encodePath(path)}`, {
      responseType: 'json',
      observe: "body"
    });
  }
}
