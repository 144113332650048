import {Directive, HostListener, inject, Input} from '@angular/core';
import {saveAs} from "file-saver";
import {
  FileManagerService
} from "@sv/sv-common/lib/services/file-upload-service/file-manager.service";

@Directive({
  selector: '[svDownloadAsset]',
  standalone: true,
})
export class DownloadAssetDirective {
  @Input({required: true}) bucket!: string;
  @Input({required: true}) path!: string;
  @Input() name!: string;
  private fileManagerService = inject(FileManagerService);

  constructor() {
  }

  @HostListener('click')
  onClick() {
    this.fileManagerService.downloadFile(this.bucket + '/' + this.path).subscribe(response => {
      const fileName = this.getFileName(response.headers.get("Content-Disposition"));
      saveAs(response.body as Blob, fileName);
    })
  }

  downloadFile() {

  }

  private getFileName(contentDisposition: string | null): string {
    if (!contentDisposition) return 'unknown_file';

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);

    if (matches && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }

    return 'unknown_file';
  }
}
